let aMap = {}
aMap.getLocation = (success, fail) => {
   
    AMap.plugin('AMap.Geolocation', () => {
        console.log('开始定位---');
        var geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);

        function onComplete(data) {
            // data是具体的定位信息
            console.log('精准定位成功：', data);
            let position = {}
            position.lat = data.position.lat
            position.lng = data.position.lng
            if (data.addressComponent && data.addressComponent.province) {
                position.city = data.addressComponent.province + data.addressComponent.city
                console.log(position.city)
            }
            if (success) { success(position) }
        }

        function onError(data) {
            // 定位出错
            console.log('定位失败：', data);
            aMap.getLngLatLocation((result) => {
                if (fail) { fail(result) }
            }, () => {
                if (fail) { fail() }
            })
        }
    })
}

aMap.getLngLatLocation = (success, fail) => {
    AMap.plugin('AMap.CitySearch', () => {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
                // 查询成功，result即为当前所在城市信息
                console.log('通过ip获取当前城市：', result)
                if (success) { success(result) }
            } else {
                if (fail) { fail() }
            }
        })
    })
}

export default aMap

