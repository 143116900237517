

import originJSONP from 'jsonp'

let api = {}

api.search = function (city, keyword, page_inde, success, fail) {
    originJSONP(`https://apis.map.qq.com/ws/place/v1/search?keyword=${keyword}&key=IEPBZ-UNNC3-EUV37-OBZNF-HBLQK-UKBZB&boundary=region(${city},1)&output=jsonp&page_size=20&page_inde=${page_inde}&filter=category<>基础设施`, {}, (err, data) => {
        //url地址 //{}参数 //(err,data)错误信息，获取的数据
        // console.log(data)
        if (data && data.data) {
            if (success)
                success(data)
        } else {
            if (fail)
                fail()
        }
    })
}

api.near = function (lat, lng, page_inde, success, fail) {

    originJSONP(`https://apis.map.qq.com/ws/place/v1/search?boundary=nearby(${lat}%2C${lng}%2C1000)&page_size=20&page_index=${page_inde}&key=IEPBZ-UNNC3-EUV37-OBZNF-HBLQK-UKBZB&output=jsonp`, {}, (err, data) => {
        //url地址 //{}参数 //(err,data)错误信息，获取的数据
        // console.log(data)
        if (data && data.status == 0) {
            if (success)
                success(data)
        } else {
            if (fail)
                fail()
        }
    })
}

api.search_new = function (city, keyword, page_inde, success, fail) {//&types=120000
    originJSONP(`https://restapi.amap.com/v3/place/text?keywords=${keyword}&offset=20&page=${page_inde}&extensions=all&key=a3b34d7be9889bc9bb5da230a0bedeaa&output=JSON`, {}, (err, data) => {
        //url地址 //{}参数 //(err,data)错误信息，获取的数据
        // console.log(data)
        if (data && data.info == 'OK') {
            if (success)

            data.pois.forEach(element => {
                element.title = element.name
                let latlng = element.location.split(",");
                element.location={}
                element.location.lng = latlng[0]
                element.location.lat = latlng[1]
                element.ad_info = {}
                element.ad_info.adcode = element.adcode
                element.ad_info.city = element.cityname
                element.ad_info.district = element.adname
                element.ad_info.province = element.pname

                if (element.pname == element.cityname) {
                    element.address = element.cityname + element.adname + element.address
                }else{
                    element.address = element.pname + element.cityname + element.adname + element.address
                }
                
                element.category = element.type
            });
            console.log(data)
                success(data)
        } else {
            if (fail)
                fail()
        }
    })
}

api.near_new = function (lat, lng,distance, page_inde, success, fail) {

    originJSONP(`https://restapi.amap.com/v3/place/around?location=${lng},${lat}&radius=${distance}&offset=20&page=${page_inde}&extensions=all&key=a3b34d7be9889bc9bb5da230a0bedeaa&output=JSON`, {}, (err, data) => {
        //url地址 //{}参数 //(err,data)错误信息，获取的数据
        // console.log(data)
        if (data && data.info == 'OK') {
            if (success)

            data.pois.forEach(element => {
                element.title = element.name
                let latlng = element.location.split(",");
                element.location={}
                element.location.lng = latlng[0]
                element.location.lat = latlng[1]
                element.ad_info = {}
                element.ad_info.adcode = element.adcode
                element.ad_info.city = element.cityname
                element.ad_info.district = element.adname
                element.ad_info.province = element.pname

                if (element.pname == element.cityname) {
                    element.address = element.cityname + element.adname + element.address
                }else{
                    element.address = element.pname + element.cityname + element.adname + element.address
                }
                
                element.category = element.type
            });
            // console.log(data)
                success(data)
        } else {
            if (fail)
                fail()
        }
    })
}

export default api