import Vue from 'vue'
import VueRouter from 'vue-router'
// import ChooseLocation from '../views/ChooseLocation.vue'
import Distribution from '../views/Distribution.vue'
import ChooseLocationNew from '../views/ChooseLocationNew.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ChooseLocationNew',
    component: ChooseLocationNew
  },
  {
    path: '/chooseLocation',
    name: 'ChooseLocationNew',
    component: ChooseLocationNew
  },
  {
    path: '/distribution',
    name: 'Distribution',
    component: Distribution
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
