<template>
  <div class="root">
    <!-- 搜索-禁止输入 -->
    <div @click="clickSearch" v-show="isInitMap">
      <van-search
        class="input-s"
        placeholder="搜索地点"
        input-align="center"
        disabled
      />
    </div>

    <!-- 地图模块 -->
    <div class="mapBg">
      <div ref="container" id="container">
        
      </div>
      <!-- 大头针和pop -->
        <div class="locationIconBg" v-show="isInitMap">
          <div
            id="talkbubble"
            class="inlayer"
            v-if="!locationAction && isShowPop"
          >
            <div class="dalogext">
              {{ isShowPop ? nearAddressList[0].title : "" }}
            </div>
          </div>
          <van-icon
            name="location"
            :class="locationAction ? 'locationIcon-A' : 'locationIcon'"
          />
          <div
            :class="
              locationAction ? 'locationIconShadow-A' : 'locationIconShadow'
            "
          ></div>
        </div>
      <!-- 定位按钮 -->
      <div class="location-flag" @click="gotoLocation" v-show="isInitMap">
        <van-icon class="location-flag-icon" name="flag-o" />
      </div>
    </div>

    <!-- 附近地址模块 -->
    <div class="item-n-scroll" ref="scroll" @scroll="scroll" v-show="isInitMap">
      <div
        class="item-n"
        v-for="(item, i) in nearAddressList"
        :key="i"
        @click="addAddress(i)"
      >
        <van-icon class="item-n-icon" name="location" v-if="i == 0" />
        <van-icon class="item-n-icon-n" name="stop-circle" v-if="i != 0" />
        <!-- <div class="item-n-icon-c" v-if="i != 0">
          <div class="item-n-icon-circle"></div>
        </div> -->
        <div>
          <div class="item-n-title">{{ item.title }}</div>
          <div class="item-n-address">{{ item.address }}</div>
        </div>
        <div class="line-n"></div>
      </div>
      <div class="loading" v-if="isMore">
        <van-loading size="24px">加载中...</van-loading>
      </div>
    </div>

    <!-- 地址搜索模块 -->
    <div :class="showSearch ? 'keywordSearch' : 'hidekeywordSearch'">
      <van-search
        v-model="keyword"
        placeholder="搜索地点"
        input-align="left"
        show-action
        @cancel="cancel"
        @input="inputKeyWord"
        @focus="enablefocus"
        :class="showSearch ? 'showSearchInput' : 'hideSearchInput'"
      />

      <div class="item-s-scroll">
        <div
          class="item-s"
          v-for="(item, i) in searchAddress"
          :key="i"
          @click="chooseAddress(i)"
        >
          <van-icon class="item-s-icon" name="location-o" />
          <div class="item-s-bg">
            <div class="item-s-title" v-html="item.titleReplace">
              <!-- {{ item.title }} -->
            </div>
            <div class="item-s-address">{{ item.address }}</div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- loading模块 等待地图文件导入 -->
    <van-loading
      v-if="isLoading"
      class="loadingscreen"
      type="spinner"
      color="#1989fa"
    />
  </div>
</template>

<script>
// import map from "../uitls/chooseLocation";
import mapApi from "../api/chooseLocationApi";
import aMap from "../uitls/aMapGeolocation";

export default {
  name: "ChooseLocationNew",
  data() {
    return {
      map: null,
      marker: null,
      label: null,
      position: null,
      city: "北京市",
      showSearch: false,
      keyword: "",
      searchAddress: [],
      isMore: false,
      isLoading: true,
      isInitMap: false,
      page_inde: 1,
      selectAddress: null,
      nearAddressList: [],
      timer: null,
      timer1: null,
      locationAction: false,
      isShowPop: false,
      tilesloadedCount: 1,
    };
  },
  components: {},
  created() {},
  mounted() {
    // 地图相关三方文件加载结束回调
    if (window.AMap != undefined) {
      this.isInitMap = true;
      this.initMap();
    } else {
      window.addEventListener("initAMap", () => {
        this.isInitMap = true;
        this.initMap();
      });
    }
  },
  updated() {},
  methods: {
    initMap() {
      let coordlatlng = {};
      coordlatlng.lat = 39.90960456049752;
      coordlatlng.lng = 116.3972282409668;
      this.getCoord((lat, lng) => {
        coordlatlng.lat = lat;
        coordlatlng.lng = lng;
      });

    this.map = new AMap.Map('container',{
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom:16, //初始化地图层级
        center: [coordlatlng.lng, coordlatlng.lat] ,//设置地图中心点坐标
        // layers: [new AMap.TileLayer.Satellite()],  //设置图层,可设置成包含一个或多个图层的数组
        viewMode: '2D',  //设置地图模式
        lang:'zh_cn',  //设置地图语言类型
    });
   
      this.changeMapStyle();
      // 首次加载数据
      this.getNearData(null, coordlatlng.lat, coordlatlng.lng,true);
      // 初始化大头针
    //   this.marker = map.initMarker(this.map);

  
      // 地图发生拖拽
      this.map.on('dragging', () => {
        console.log("发生拖拽了");
        this.selectAddress = null;
        this.locationAction = true;
      });

      this.map.on( "dragend", () => {
        console.log("拖拽结束了");
        this.locationAction = false;
        this.center_changed();
      });

      this.map.on("zoomend", () => {
        this.selectAddress = null;
        this.locationAction = true;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          console.log("地图发生缩放");
        }, 50);
      });

      // 加载地图结束
      this.map.on("complete", () => {
        if (this.tilesloadedCount == 1) {
          this.tilesloadedCount = this.tilesloadedCount + 1;
          // 恢复缩放比例
          this.map.setZoom(16);
        }
        this.changeMapStyle();
      });

      // 删除地图控件触发11
    //   map.onevent(this.map, "control_removed", () => {
    //     this.changeMapStyle();
    //   });

      // 发起腾讯定位和获取链接入参
      this.getCoord(
        (lat, lng) => {
          this.map.setCenter([lng,lat]); 
          this.selectAddress = null;
          this.center_changed();
          aMap.getLocation(
                (position) => {this.position = position;},(showErr) => {});
        },
        () => {
            aMap.getLocation(
                (position) => {
                    this.position = position;
                    this.city = this.position.city;
                    this.map.setCenter([position.lng,position.lat]); 
                    this.selectAddress = null;
                    this.center_changed();
                },
                (showErr) => {}
           );
        }
      );
    },
    center_changed(){
      if (this.timer1) {
          clearTimeout(this.timer1);
        }
        this.timer1 = setTimeout(() => {
          console.log("地图中心点变化");
          var centerLatLng = this.map.getCenter()
          console.log('centerLatLng=')
          console.log(centerLatLng)
          // 请求周边地址信息
          this.$refs.scroll.scrollTop = 0;
          this.page_inde = 1;
          this.getNearData(null, centerLatLng.lat, centerLatLng.lng,true);
        }, 50);
    },
    getCoord(coordCallBack, nothingCallBack) {
      let coord = this.getQueryString("coord");
      let latlng = [];
      if (coord) {
        latlng = coord.split(",");
      }
      if (coord && latlng.length == 2) {
        if (coordCallBack) {
          coordCallBack(latlng[0], latlng[1]);
        }
      } else {
        if (nothingCallBack) {
          nothingCallBack();
        }
      }

      aMap.getLngLatLocation((result)=>{
        console.log("当前所在城市：", result.city);
        this.city = result.city;
      },()=>{} )
    },
    clickSearch() {
      this.showSearch = true;
    },
    cancel() {
      this.showSearch = false;
      this.keyword = "";
      this.searchAddress = [];
      this.page_inde = 1;
    },
    inputKeyWord(value) {
      this.page_inde = 1;
      this.searchAddress = [];
      this.searchData(value);
    },
    enablefocus() {
      this.showSearch = true;
    },
    searchData(value) {
      mapApi.search_new(this.city, value, this.page_inde, (res) => {
        this.isLoading = false;
        if (res && res.pois) {
          this.searchAddress = res.pois;

          for (let i = 0; i < res.pois.length; i++) {
            let item = res.pois[i];
            if (item.title) {
              let text = item.title;
              let res = new RegExp("(" + this.keyword + ")", "g");
              text = text.replace(
                res,
                "<span style='color:#999999;'>" + this.keyword + "</span>"
              );
              item.titleReplace = text;
            }
          }
        }
      });
    },
    scroll(e) {
      // console.log("高度");
      let height = this.$refs.scroll.offsetHeight;
      let t = this.$refs.scroll.scrollTop;
      let h = this.$refs.scroll.scrollHeight;
      if (height + t + 30 >= h) {
        //加载更多
        // console.log("加载更多");
        this.isMore = true;
        var centerLatLng = this.map.getCenter()
        this.getNearData(null, centerLatLng.lat, centerLatLng.lng,false);
      }
    },
    chooseAddress(index) {
      //选择一个地址
      let address = this.searchAddress[index];
      if (
        address &&
        address.address &&
        address.title &&
        address.location &&
        address.ad_info &&
        address.ad_info.city
      ) {
        this.selectAddress = address;
      } else {
        this.selectAddress = null;
      }

      console.log("选中的地址");
      console.log(address);
      // 退出搜索页
      this.cancel();
      // 改变地图中心点
      this.map.setCenter([address.location.lng,address.location.lat]); 
      // 恢复缩放比例
      this.map.setZoom(16);
      this.locationAction = true;
      this.center_changed();
    },
    getNearData(address, lat, lng,first) {
      if (this.page_inde == 1) {
        this.isShowPop = false;
      }
      const distance = this.getQueryString("distance") || '1000';

      mapApi.near_new(
        lat,
        lng,
        distance,
        this.page_inde,
        (res) => {
          // console.log(res);
          // alert(JSON.stringify(res))
          
          if(first){
            this.page_inde = 1 ;
            this.nearAddressList = [];
          }
          this.locationAction = false;
          this.isLoading = false;
          this.isNearLoadFail = true;
          if (res && res.pois && res.pois.length > 0) {
            if (this.page_inde == 1) {
              this.nearAddressList = [];
              if (this.selectAddress) {
                this.nearAddressList.push(this.selectAddress);
              }
            }
            this.page_inde = this.page_inde + 1;
            this.isMore = false;
            for (let i = 0; i < res.pois.length; i++) {
              let item = res.pois[i];
              if (this.selectAddress && item.id == this.selectAddress.id) {
              } else {
                this.nearAddressList.push(item);
              }
            }
            if (this.nearAddressList.length > 0) {
              this.isShowPop = true;
              this.isNearLoadFail = true;
            }
            // this.nearAddressList = this.nearAddressList
          }
        },
        () => {}
      );
    },
    addAddress(index) {
      let address = this.nearAddressList[index];
      // console.log(address);
      let msg = {};
      msg.module = "locationPicker";
      msg.latlng = {};
      msg.latlng.lat = address.location.lat;
      msg.latlng.lng = address.location.lng;
      msg.poiaddress = address.address;
      msg.poiname = address.title;
      msg.cityname = address.ad_info.city;
      msg.data = address;
      console.log(msg);
      window.parent.postMessage(msg, "*");
    },
    gotoLocation() {
      console.log("开始定位")
      if(this.position){
              this.city = this.position.city;
              this.map.setCenter([this.position.lng,this.position.lat]); 
              this.map.setZoom(16);
              this.selectAddress = null;
              this.center_changed();
      }else{
          aMap.getLocation(
           (position) => {
              this.position = position;
              this.city = this.position.city;
              this.map.setCenter([position.lng,position.lat]); 
              this.map.setZoom(16);
              this.selectAddress = null;
              this.center_changed();
           },
          (showErr) => {})
      }
      
      
    },
    changeMapStyle() {
      var logos = document.getElementsByClassName("amap-copyright");
      if (logos && logos.length > 0) {
        let logo = logos[0];
        logo.style.opacity = 0;
      }
      // console.log(logos);
    },
  },
};
</script>

<style scoped>
.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.mapBg {
  position: relative;
}
#container {
  /*地图(容器)显示大小*/
  width: 100vw;
  height: 36vh;
  position: relative;
}

.keywordSearch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10000;
  display: flex;
  flex-direction: column;
}

.hidekeywordSearch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 10000;
  display: flex;
  flex-direction: column;
}

.showSearchInput {
  opacity: 1;
}

.hideSearchInput {
  opacity: 0;
}

.item-s-scroll {
  overflow: scroll;
  flex: 1;
  background-color: #ffffff;
}

.item-s {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0.1rem 0;
  position: relative;
}

.item-s-icon {
  margin-left: 0.1rem;
  text-align: center;
  color: #999999;
}
.item-s-bg {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.item-s-title {
  color: #333333;
  font-size: 0.14rem;
  font-weight: bolder;
}
.item-s-address {
  color: #999999;
  font-size: 0.12rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.6rem;
}

.line {
  background-color: #eeeeee;
  height: 0.5px;
  position: absolute;
  left: 0.3rem;
  right: 0.1rem;
  bottom: 0;
}

.item-n-scroll {
  position: relative;
  overflow: scroll;
  flex: 1;
  padding: 0 0.15rem;
  margin-top: 0.05rem;
  background-color: #ffffff;
  /* margin-top: -200px; */
  z-index: 10000;
}
.item-n {
  padding: 0.1rem 0;
  position: relative;
  display: flex;
  align-items: center;
}

.item-n-icon {
  margin-right: 0.1rem;
  color: #1d87ec;
  font-weight: bolder;
  width: 0.15rem;
  font-size: 0.15rem;
  text-align: center;
  font-weight: bolder;
}

.item-n-icon-n {
  margin-right: 0.1rem;
  color: #999999;
  font-weight: bolder;
  width: 0.15rem;
  font-size: 0.12rem;
  text-align: center;
  font-weight: bolder;
}

.item-n-icon-c {
  margin-right: 0.1rem;
  width: 0.15rem;
  height: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-n-icon-circle {
  width: 0.05rem;
  height: 0.05rem;
  border-radius: 50%;
  border: 0.03rem solid #999999;
}

.item-n-title {
  color: #333333;
  font-size: 0.14rem;
}

.item-n:nth-child(1) .item-n-title {
  font-weight: bolder;
  color: #1d87ec;
}

.item-n-address {
  color: #999999;
  font-size: 0.12rem;
}

.line-n {
  background-color: #eeeeee;
  height: 1px;
  position: absolute;
  left: 0rem;
  right: 0rem;
  bottom: 0;
}

.loadingscreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.locationIconBg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.3rem;
  height: 0.34rem;
  font-size: 0.3rem;
  line-height: 0.3rem;
  color: #1d87ec;
  margin-top: -0.17rem;
  
}

.locationIcon {
  /* margin-top: -0.3rem; */
  position: absolute;
  top: 0;
  transition: top 0.2s;
}

.locationIconShadow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  width: 0.05rem;
  height: 0.03rem;
  background-color: #00000044;
  border-radius: 50%;

  transition: width 0.2s, height 0.2s;
}

.locationIcon-A {
  /* margin-top: -0.3rem; */
  position: absolute;
  top: -0.1rem;
  transition: top 0.2s;
}

.locationIconShadow-A {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  width: 0.11rem;
  height: 0.06rem;
  background-color: #00000044;
  border-radius: 50%;

  transition: width 0.2s, height 0.2s;
}

/* 对话框 */
#talkbubble {
  /* width: 120px; */
  text-align: center;
  padding: 0.05rem 0.05rem 0.04rem 0.05rem;
  display: inline-block;
  position: absolute;
  border-radius: 0.07rem;
  border: 1px #cccccc solid;
  background-color: #ffffff;
  font-size: 0.1rem;
  line-height: 0.12rem;
  z-index: 1000000;
  box-shadow: 0px 0px 5px #999999;
  top: -0.3rem;
  left: 50%;
  transform: translate(-50%);
}

.dalogext {
  max-width: 35vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333333;
  font-weight: bolder;
}

#talkbubble:before {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-top: 5px solid #cccccc;
  border-right: 5px solid transparent;
}
.inlayer:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
}

.location-flag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.37rem;
  height: 0.37rem;
  font-size: 0.21rem;
  font-weight: bolder;
  border-radius: 0.2rem;
  color: #1d87ec;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #999999;
  bottom: 0.12rem;
  right: 0.12rem;
  z-index: 10000;
}
</style>