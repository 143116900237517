<template>
  <!-- 地图模块 -->
  <div class="mapBg">
    <div ref="container1" id="container1"></div>
    <!-- loading模块 等待地图文件导入 -->
    <van-loading
      v-if="isLoading"
      class="loadingscreen"
      type="spinner"
      color="#1989fa"
    />
  </div>
</template>

<script>
import map from "../uitls/distribution";
export default {
  name: "Distribution",
  data() {
    return {
      map: null,
      marker: null,
      isLoading: true,
    };
  },
  components: {},
  created() {},
  mounted() {
    // 地图相关三方文件加载结束回调
    if (window.TMap != undefined) {
      this.isInitMap = true;
      this.initMap();
    } else {
      window.addEventListener("initMap", () => {
        this.isInitMap = true;
        this.initMap();
      });
    }
  },
  methods: {
    initMap() {
      this.isLoading = false;
      let coordlatlng = {};
      coordlatlng.lat = 39.90960456049752;
      coordlatlng.lng = 116.3972282409668;
      // 初始化地图
      this.map = map.initMap(
        coordlatlng.lat,
        coordlatlng.lng,
        this.$refs.container1
      );
      this.changeMapStyle();
      this.marker = map.initMarker(this.map);

      map.initInfoWindow();
      this.setPositions();
      this.eventListener();
      // setTimeout(() => {
      //   map.resetZoom(this.map);
      // }, 1000);
      // let locations = [];
      // {
      //   let location = {};
      //   location.coord = {};
      //   location.coord.lat = "39.022964";
      //   location.coord.lng = "116.819723";
      //   location.coord.styleId = "business";
      //   location.pop = {};
      //   location.pop.imgurl =
      //     "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/623dq29obvcmjfgupk34fe7g59.png";
      //   location.pop.title = "商家已接单";
      //   location.pop.text = "等待骑手配送";
      //   locations.push(location);
      // }

      // {
      //   let location = {};
      //   location.coord = {};
      //   location.coord.lat = "38.022964";
      //   location.coord.lng = "116.319723";
      //   location.coord.styleId = "rider";
      //   location.pop = {};
      //   location.pop.imgurl =
      //     "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/cudhns9m5o35jjs43onle3uv05.png";
      //   location.pop.title = "店员配送中";
      //   location.pop.text = "距离您500m";
      //   locations.push(location);
      // }

      // {
      //   let location = {};
      //   location.coord = {};
      //   location.coord.lat = "38.522964";
      //   location.coord.lng = "118.319723";
      //   location.coord.styleId = "address";
      //   location.pop = {};
      //   location.pop.imgurl =
      //     "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/jpd3etgulcr8587bnqadl3dng3.png";
      //   location.pop.title = "订单已支付";
      //   location.pop.text = "等待商家接单";
      //   locations.push(location);
      // }

      // // console.log("=====");
      // console.log(encodeURIComponent(JSON.stringify(locations)));
    },
    changeMapStyle() {
      var logos = document.getElementsByClassName("logo-text");
      if (logos && logos.length > 0) {
        let logo = logos[0];
        logo.style.opacity = 0;
      }
    },
    setPositions(callBack) {
      let positionsStr = this.getQueryString("locations");
      if (positionsStr) {
        positionsStr = decodeURIComponent(this.getQueryString("locations"));
      }
      let locations = {};
      if (positionsStr) {
        locations = JSON.parse(positionsStr);
      }
      if (locations && locations.length > 0) {
        this.addInfoWindow(locations, true);
      }
    },
    eventListener() {
      window.addEventListener("message", (event) => {
        let data = event.data;
        if (data && data.module == "distribution") {
          if (data.locations) {
            this.addInfoWindow(locations, false);
          }
        }
      });
    },
    addInfoWindow(locations, delay) {
      map.removeAllMarker(this.marker);
      map.removeAllInfoWindow();
      let markerArr = [];
      let polylineArr = [];
      locations.forEach((element, index) => {
        element.coord.id = "distributionId" + index;
        element.coord.position = new TMap.LatLng(
          element.coord.lat,
          element.coord.lng
        );
        polylineArr.push(element.coord.position);
        markerArr.push(element.coord);
        map.updataInfoWindow(this.map, this.marker, element.coord, element.pop);
      });

      // 自适应
      map.showMarker(this.map, markerArr);

      if (delay) {
        setTimeout(() => {
          // 画连接线
          map.addMultiPolyline(this.map, polylineArr);
        }, 1000);
      } else {
        // 画连接线
        map.addMultiPolyline(this.map, polylineArr);
      }

      if (locations && locations.length <= 1) {
        setTimeout(() => {
          // 当只有一个点时恢复缩放比例
          map.resetZoom(this.map);
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
#container1 {
  /*地图(容器)显示大小*/
  width: 100vw;
  height: 100vh;
  position: relative;
}
.loadingscreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>