
import aMap from "./aMapGeolocation";

let distribution = {}

distribution.initMap = function (lat, lng, map_dom) {
    //定义地图中心点坐标
    var center = new TMap.LatLng(lat, lng);
    //定义map变量，调用 TMap.Map() 构造函数创建地图
    var map = new TMap.Map(map_dom, {
        center: center, //设置地图中心点坐标
        zoom: 12.5, //设置地图缩放级别
        minZoom: 0.1,
        pitch: 43.5, //设置俯仰角
        rotation: 45, //设置地图旋转角度
        viewMode: "2D",
    });
    // let control = map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
    // control.setPosition(TMap.constants.CONTROL_POSITION.BOTTOM_RIGHT);

    if (map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM)) {
        map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
    }
    if (map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION)) {
        map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
    }
    if (map.getControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE)) {
        map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);
    }

    return map;
}

distribution.resetZoom = function (map) {
    map.easeTo({ zoom: 13.7 }, { duration: 2000 });
}

distribution.getCenterLatLng = function (map, callback) {
    let centerLatLng = map.getCenter();
    if (callback) callback(centerLatLng);
}

distribution.onevent = function (map, name, callback) {
    let dragendHandler = function (evt) {
        if (callback) callback();
    };
    //Map实例创建后，通过on方法绑定点击事件
    map.on(name, dragendHandler);
}

distribution.initMarker = function (map) {

    distribution.markers = []
    distribution.myIWs = []
    return new TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
            //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            default: new TMap.MarkerStyle({
                width: 20, // 点标记样式宽度（像素）
                height: 25, // 点标记样式高度（像素）
                opacity: 1,
                src: "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/4vpv12ha3f8eqlfrl7afp5ivqf.png", //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 10, y: 24 },
            }),
            business: new TMap.MarkerStyle({
                width: 20, // 点标记样式宽度（像素）
                height: 25, // 点标记样式高度（像素）
                opacity: 1,
                src: "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/gfkhumcg72d3p2tp40j0mme44s.png", //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 10, y: 24 },
            }),
            address: new TMap.MarkerStyle({
                width: 20, // 点标记样式宽度（像素）
                height: 25, // 点标记样式高度（像素）
                opacity: 1,
                src: "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/j6m9fgc8gu6bmcmuas9eagl6qf.png", //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 10, y: 24 },
            }),
            rider: new TMap.MarkerStyle({
                width: 14, // 点标记样式宽度（像素）
                height: 14, // 点标记样式高度（像素）
                opacity: 1,
                src: "https://uniondrug.oss-cn-hangzhou.aliyuncs.com/frontend.wx/15idqds5pak44ce74543gchq2k.png", //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 7, y: 7 },
            }),
        },
    });
}

distribution.addMarker = function (marker, id, lat, lng, title, myStyle) {
    marker.add([
        {
            id: id, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: myStyle, //指定样式id
            position: new TMap.LatLng(lat, lng), //点标记坐标位置
            properties: {
                //自定义属性
                title: title,
            },
        },
    ]);
    if (distribution.markers) {
        distribution.markers.push(id)
    }
}

distribution.updateMarker = function (marker, id, lat, lng, title, myStyle) {
    marker.updateGeometries([
        {
            styleId: myStyle,
            id: id,
            position: new TMap.LatLng(lat, lng),
            title: title,
        },
    ]);
}

distribution.removeAllMarker = function (marker) {
    if (distribution.markers) {
        marker.remove(distribution.markers);
    }
    distribution.markers = []
}

distribution.initInfoWindow = function () {
    var mydom;
    //自定义DOM覆盖物 - 继承DOMOverlay
    function myInfoWindow(options) {
        TMap.DOMOverlay.call(this, options);
    }
    myInfoWindow.prototype = new TMap.DOMOverlay();

    // 初始化
    myInfoWindow.prototype.onInit = function (options) {
        this.position = options.position;
        this.content = options.content;
    };

    // 创建DOM元素，返回一个DOMElement
    myInfoWindow.prototype.createDOM = function () {
        mydom = document.createElement("div");
        //设置DOM样式
        mydom.style.cssText = 'padding:0px;position:absolute;top:0px;left:0px;';
        mydom.innerHTML = this.content;
        return mydom;
    };

    // 更新DOM元素，在地图移动/缩放后执行
    myInfoWindow.prototype.updateDOM = function () {
        if (!this.map) {
            return;
        }

        // 经纬度坐标转容器像素坐标
        let pixel = this.map.projectToContainer(this.position);

        //默认使用DOM左上角作为坐标焦点进行绘制（左上对齐）
        //如想以DOM中心点（横向&垂直居中）或其它位置为焦点，可结合this.dom.clientWidth和this.dom.clientHeight自行计算
        let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'; //本例水平居中
        let top = pixel.getY() + 'px';

        //将平面坐标转为三维空间坐标
        this.dom.style.transform = `translate3d(${left}, ${top}, 0px)`;
    };

    //自定义一个更新内容的方法 
    myInfoWindow.prototype.updateContent = function (imgurl, title, text, styleId) {

        mydom.innerHTML = `
  <style>
  .talkbubble {
  text-align: center;
  padding: 8px 8px 8px 8px ;
  display: inline-block;
  position: absolute;
  border-radius: 7px;
  border: 1px #cccccc solid;
  background-color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  z-index: 1000000;
  box-shadow: 0px 0px 5px #aaaaaa;
  top: -95px;
  height: 42px;
  left: 50%;
  transform: translate(-50%);
}
.talkbubble_1{
    top: -77px;
}

#dalogext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333333;
  font-weight: bolder;
  display: flex;
  align-items: center;
 
  flex-direction: row;
  margin-top: -0px;
  text-align: left;
  height: 100%;
  min-width: 155px;
}

.talkbubble:before {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-top: 5px solid #cccccc;
  border-right: 5px solid transparent;
}
.talkbubble:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
}
#dalogext-icon{
  width: 38px;
  height: 38px;
  margin-right: 8px;
}
#dalogext-title{
font-size: 14px;
line-height: 14px;
color: #333333;
margin: 0 0 6px 0 ;
}
#dalogext-text{
font-size: 12px;
line-height: 12px;
color: #666666;
margin: 0 ;
}
</style>
`
        let styleClass = ''
        if (styleId == 'rider') {
            styleClass = 'talkbubble_1'
        }
        mydom.innerHTML += `<div
            class="talkbubble ${styleClass}" 
          >
            <div id="dalogext">
              <img id="dalogext-icon" src=${imgurl}></img>
              <div>
                <p id="dalogext-title">${title}</p>
                <p id="dalogext-text">${text}</p>
              </div>
            </div>
          </div>`;
    };
    distribution.myInfoWindow = myInfoWindow
}

distribution.infoWindow = function (map, lat, lng, imgurl, title, text, styleId) {
    //创建一个自定义的infoWindow
    var myIW = new distribution.myInfoWindow({
        map: map,
        position: new TMap.LatLng(lat, lng),
        content: "",
        offset: { x: 0, y: -32 }
    })
    //两秒钟后更新文字内容
    myIW.updateContent(imgurl, title, text, styleId)

    if (distribution.myIWs) {
        distribution.myIWs.push(myIW)
        console.log('信息窗口', distribution.myIWs)
    }
}

distribution.updataInfoWindow = function (map, marker, coord, pop) {
    // console.log('信息窗口', coord, pop)
    if (coord && coord.lat && coord.lng) {
        distribution.addMarker(marker, coord.id, coord.lat, coord.lng, '', coord.styleId)
        distribution.setCenter(map, coord.lat, coord.lng)
        if (pop && pop.imgurl && pop.title && pop.text) {
            setTimeout(() => {
                distribution.infoWindow(map, coord.lat, coord.lng, pop.imgurl, pop.title, pop.text, coord.styleId)
            }, 1000);
        }
    }
}

distribution.removeAllInfoWindow = function () {
    if (distribution.myIWs) {
        for (let index = 0; index < distribution.myIWs.length; index++) {
            const element = array[index];
            element.destroy()
        }
    }
    distribution.myIWs = []
}

//设置自适应显示marker
distribution.showMarker = (map, markerArr) => {

    if (!distribution.bounds) {
        //初始化
        distribution.bounds = new TMap.LatLngBounds();
    }
    //判断标注点是否在范围内
    markerArr.forEach(function (item) {
        //若坐标点不在范围内，扩大bounds范围
        if (distribution.bounds.isEmpty() || !distribution.bounds.contains(item.position)) {
            distribution.bounds.extend(item.position);
        }
    })
    var container = document.getElementById("container1");
    let height = container.offsetHeight
    let width = container.offsetWidth
    if (height > width) {
        //设置地图可视范围
        map.fitBounds(distribution.bounds, {
            padding: { top: 100 * 1.3 * height / width, bottom: 100 * 1.3 * height / width, left: 100, right: 100 }// 自适应边距
        });
    } else {
        //设置地图可视范围
        map.fitBounds(distribution.bounds, {
            padding: { top: 100, bottom: 100, left: 100, right: 100 }// 自适应边距
        });
    }

}

distribution.setCenter = function (map, lat, lng, callback) {
    map.setCenter(new TMap.LatLng(lat, lng));
    var centerLatLng = map.getCenter();
    if (callback) callback(centerLatLng);
}

distribution.getAMapLocation = function (success, fail) {
    aMap.getLocation(
        (position) => {
            // console.log("位置");
            if (success) {
                success(position);
            }
        },
        (showErr) => {
            // console.log("出错了");
            if (fail) {
                fail(showErr);
            }
        }
    );
}

distribution.geolocation = function (success, fail) {

    if (window.AMap != undefined) {
        distribution.getAMapLocation(success, fail)
    } else {
        window.addEventListener("initAMap", () => {
            distribution.getAMapLocation(success, fail)
        });
    }
}

distribution.getCityLocation = function (success, fail) {
    if (window.AMap != undefined) {
        aMap.getLngLatLocation(success, fail)
    } else {
        window.addEventListener("initAMap", () => {
            aMap.getLngLatLocation(success, fail)
        });
    }
}

distribution.addMultiPolyline = function (map, paths) {
    if (!distribution.polylineLayer) {
        var polylineLayer = new TMap.MultiPolyline({
            id: 'polyline-layer', //图层唯一标识
            map: map,//设置折线图层显示到哪个地图实例中
            //折线样式定义
            styles: {
                'style_ud1': new TMap.PolylineStyle({
                    'color': '#FF8200', //线填充色
                    'width': 1, //折线宽度
                    'borderWidth': 0, //边线宽度
                    'borderColor': '#FF8200', //边线颜色
                    'lineCap': 'butt',//线端头方式
                    'dashArray': [5, 2]
                })
            }
        });
        distribution.polylineLayer = polylineLayer
        distribution.polylineLayerPaths = []
    }
    if (distribution.polylineLayerPaths.length > 0) {
        distribution.polylineLayer.remove(distribution.polylineLayerPaths)
        distribution.polylineLayerPaths = []
    }
    if (paths && paths.length > 1) {
        distribution.polylineLayer.add([
            {
                'id': 'pl_1',//折线唯一标识，删除时使用
                'styleId': 'style_ud1',//绑定样式名
                'paths': paths
            }
        ])
        distribution.polylineLayerPaths.push['pl_1']
    }
}

export default distribution