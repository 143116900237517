import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Search } from 'vant';
import { Icon } from 'vant';
import { Loading } from 'vant';
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Search);

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

function setRem() {
  //监听窗口变化
  var html = document.querySelector('html');
  var homeWith = window.innerWidth;
  // if(homeWith > 450 ){
  //   homeWith = 450 ;
  // }
  html.style.fontSize = homeWith * 100 / 375 + 'px';
}
setRem()

window.addEventListener('resize', function () {
  console.log(document.body.offsetWidth)
  setRem()
})

// function getQueryString(name) {
//   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
//   var r = window.location.search.substr(1).match(reg);
//   if (r != null) return unescape(r[2]);
//   return null;
// }

function getQueryString(name) {
  name = name.replace(/[]/, "\[").replace(/[]/, "\[").replace(/[]/, "\\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results == null)
    return "";
  else {
    return results[1];
  }
}


Vue.prototype.getQueryString = getQueryString


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
